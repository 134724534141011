("ues client");

import Helper from "@/utils/helper";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { addProductRecentViewToLocal } from "@/lib/api/shop/products";

import Local from "@/common/constants/local";
import { StoryJson } from "@/types/Story.type";
import { ProductJson } from "@/types/Product.type";
import ProductModel from "@/common/models/ProductModel";
import ProductConstants from "@/common/constants/product";
import { SidebarItemJson } from "~components/productFilter/productFilterMobile/ProductFilterSortType";
import { EventPopupJson } from "@/types/Event.type";
import {
	DataLanguageCache,
	LanguageSettingJson,
} from "@/common/types/SiteLanguage.type";
import { LanguageSetting } from "@/common/constants/language";
import { RootState } from "../store";
import { Device, SystemSetting } from "@/types/Shop.type";
import { ProductCompares } from "~components/product/ProductCompare";

type InitialState = {
	systemSetting: SystemSetting;
	popupId: string;
	productPopupId: number;
	productData: ProductJson;
	sidebarIds: number[];
	sidebarItems: SidebarItemJson[];
	reload: boolean;
	floatButtonVisile: number;
	listProductRecentView: ProductJson[];
	storeId: number;
	isShowSearchMobile: boolean;
	sidebarMobile: boolean;
	notifyMobile: boolean;
	browser_id: string;
	tagActive: string;
	notifications: { new: StoryJson[]; recent: StoryJson[] };
	currentServerTimestamp: number;
	eventPopup: EventPopupJson;
	code: string;
	language: LanguageSettingJson;
	siteLanguages: LanguageSettingJson[];
	dataLanguage: DataLanguageCache;
	device: Device;
	productCompares: ProductCompares[];

	productSendMail : ProductJson | null
};

const productCompareLocalStorage =
	typeof window !== "undefined"
		? localStorage.getItem(Local.storage.productCompare)
		: null;

const initialState: InitialState = {
	systemSetting: {
		...LanguageSetting[0],
		pagination_limit: 48,

		store_id: 0,
	},
	popupId: "",
	productPopupId: 0,
	productData: ProductModel.getDefaultData(),
	sidebarIds: [],
	sidebarItems: [],
	reload: false,
	floatButtonVisile: 0,
	listProductRecentView: [],
	storeId: 0,
	isShowSearchMobile: false,

	sidebarMobile: false,
	notifyMobile: false,
	browser_id:
		(!Helper.isServer()
			? localStorage.getItem(Local.storage.browser_id)
			: "") || "",
	tagActive: "",
	notifications: { new: [], recent: [] },
	currentServerTimestamp: 0,
	eventPopup: {
		key: "",
		count: 0,
		timestamp: 0,

	},
	productSendMail : null,
	code: "",
	language: LanguageSetting[0],
	siteLanguages: [],
	dataLanguage: { space: [], data: {}, lang: "vi", version: "" },
	device: "desktop",
	productCompares: [],
};
const ShopSlice = createSlice({
	name: "shop",
	initialState,
	reducers: {
		////
		setSystemSetting: (state, { payload }: { payload: SystemSetting }) => {
			state.systemSetting = payload;
		},
		setBrowserId: (state, action) => {
			state.browser_id = action.payload;
			localStorage.setItem(Local.storage.browser_id, action.payload);
		},
		setPopupId: (state, action) => {
			state.popupId = action.payload;
		},
		setProductQuickViewId: (state, action) => {
			state.productPopupId = action.payload;
		},
		setProductData: (state, { payload }: { payload: ProductJson }) => {
			state.productData = payload;
		},
		setSidebarIds: (state, action) => {
			state.sidebarIds = action.payload;
		},
		setSidebarItems: (state, action) => {
			state.sidebarItems = action.payload;
		},
		setReload: (state) => {
			state.reload = !state.reload;
		},
		//float
		setFloatButtonVisible: (state, action) => {
			state.floatButtonVisile = action.payload;
		},
		setCode: (state, action) => {
			state.code = action.payload;
		},
		addProductRecentView: (state, action) => {
			const list = [...state.listProductRecentView];
			// Find products that already exist if true  unshift
			const index = list.findIndex((item) => item.id === action.payload.id);
			if (index >= 0) {
				list.splice(index, 1);
			}
			const len = list.length;
			if (len < ProductConstants.PRODUCT_RECENT_VIEW.LIMIT_NUMBER_SAVE) {
				list.unshift(action.payload);
			}

			addProductRecentViewToLocal(action.payload.id);
			state.listProductRecentView = list;
		},

		addListProductRecentView: (state, action) => {
			state.listProductRecentView = action.payload;
		},
		setStoreId: (state, { payload }: { payload: number }) => {
			state.storeId = payload;
		},

		setIsShowSearchMobile: (state, action) => {
			state.isShowSearchMobile = action.payload;
		},
		setSidebarMobile: (state, action) => {
			state.sidebarMobile = action.payload;
		},
		setNotifyMobile: (state, action) => {
			state.notifyMobile = action.payload;
		},
		// setPopupsEvent: (state, action: PayloadAction<PopupEventClient[]>) => {
		//   state.popupsEvent = action.payload;
		// },
		setTagActive: (state, action) => {
			state.tagActive = action.payload;
		},
		setNotification: (
			state,
			action: PayloadAction<{ new: StoryJson[]; recent: StoryJson[] }>
		) => {
			state.notifications = action.payload;
		},
		setCurrentServerTimestamp: (state, action) => {
			state.currentServerTimestamp = action.payload;
		},
		setEventPopup: (state, action) => {
			state.eventPopup = action.payload;
		},
		setProductSendMail: (state, action) => {
			state.productSendMail = action.payload;
		},
		setLanguage: (state, { payload }: { payload: LanguageSettingJson }) => {
			state.language = payload;
		},
		setSiteLanguages: (
			state,
			{ payload }: { payload: LanguageSettingJson[] }
		) => {
			state.siteLanguages = payload;
		},
		setDataLanguage: (
			state,
			{
				payload,
			}: { payload: Omit<DataLanguageCache, "version"> & { version?: string } }
		) => {
			const spaceUnit = Array.from(
				new Set([...state.dataLanguage.space, ...payload.space])
			);
			const dataUnit = { ...state.dataLanguage.data, ...payload.data };
			// const expirationDate = new Date(
			// 	new Date().getTime() + 60 * 5 * 1000
			// ).toUTCString();

			// const expirationDate = new Date(
			// 	state.systemSetting.time_server + state.systemSetting.expired_translate
			// ).toUTCString();

			// document.local = `${Local.cookies.language}=${JSON.stringify({
			// 	space: spaceUnit,
			// 	data: dataUnit,
			// 	lang: state.language.lang,
			// })}; expires=${expirationDate}; path=/`;
			const dataLocal = getDataFromLocalStorage<DataLanguageCache>(
				Local.cookies.language
			);
			// console.log(dataLocal)
			const version = payload?.version
				? payload.version
				: dataLocal?.version
				? dataLocal.version
				: "";
			localStorage.setItem(
				Local.cookies.language,
				JSON.stringify({
					space: spaceUnit,
					data: dataUnit,
					lang: state.language.lang,
					version: version,
				})
			);
			state.dataLanguage = {
				data: dataUnit,
				space: spaceUnit,
				version: version,
				lang: dataLocal?.lang ? dataLocal.lang : "vi",
			};
		},
		setDevice: (state, { payload }: { payload: Device }) => {
			state.device = payload;
		},
		setProductCompares: (
			state,
			{ payload }: { payload: { product: ProductJson; type: "add" | "remove" } }
		) => {
			if (payload.type === "add") {
				if (
					state.productCompares.some((p) => p.product.id === payload.product.id)
				) {
					// toast.info("Sản phầm đã có trong danh sách so sánh!");
					return;
				} else {
					if (state.productCompares.length === 2) {
						// toast.info("Vui lòng xóa bớt sản phẩm để tiếp tục so sánh!");

						return;
					} else {
						const newIndex =
							state.productCompares.length === 0
								? 0
								: state.productCompares.some((i) => i.index === 0)
								? 1
								: 0;
						// const newIndex =
						//   state.productCompares.length < 3
						//     ? state.productCompares.length
						//     : state.productCompares.some((item) => item.index === 0)
						//     ? state.productCompares.some((item) => item.index === 1)
						//       ? 2
						//       : 1
						//     : 0;

						state.productCompares = [
							...state.productCompares,
							{ index: newIndex, product: payload.product },
						];

						localStorage.setItem(
							Local.storage.productCompare,
							JSON.stringify([...state.productCompares])
						);
						// toast.success("Thêm sản phầm so sánh thành công!");
					}
				}
			} else {
				const productRemove = state.productCompares.find(
					(p) => p.product.id === payload.product.id
				);
				if (productRemove) {
					state.productCompares = state.productCompares.filter(
						(p) => p.product.id !== productRemove.product.id
					);

					localStorage.setItem(
						Local.storage.productCompare,
						JSON.stringify(state.productCompares)
					);
					// toast.success("Đã gỡ sản phầm thành công!");
					return;
				}
			}
		},

		setProductComparesState: (state, action) => {
			state.productCompares = action.payload;
			localStorage.setItem(
				Local.storage.productCompare,
				JSON.stringify(action.payload)
			);
		},
	},
});

export const getDataFromLocalStorage = <T>(name: string) => {
	let result: T | null = null;
	try {
		result = (JSON.parse(localStorage.getItem(name) || "null") as T) || null;
	} catch (error) {}

	return result;
};
export const selectDevice = (state: RootState) => state.ShopReducer.device;
export const {
	setSystemSetting,
	setBrowserId,
	setPopupId,
	setProductQuickViewId,
	setSidebarIds,
	setSidebarItems,
	setReload,
	setFloatButtonVisible,
	addProductRecentView,
	addListProductRecentView,
	setStoreId,
	setIsShowSearchMobile,
	setProductData,
	// setPopupsEvent,
	setSidebarMobile,
	setNotifyMobile,
	setTagActive,
	setNotification,
	setCurrentServerTimestamp,
	setEventPopup,
	setCode,
	setLanguage,
	setDataLanguage,
	setDevice,
	setProductCompares,
	setProductComparesState,
	setProductSendMail
} = ShopSlice.actions;
export const selectDataLanguage = (state: RootState) => {
	return state.ShopReducer.dataLanguage;
};
export default ShopSlice.reducer;
