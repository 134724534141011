export const i18n = {
  defaultLocale: "vi",
  locales: ["en", "vi"],
} as const;

export type Locale = (typeof i18n)["locales"][number];
import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import i18nextResource from "@/lib/config/i18n_resource";

i18next

	// detect user language
	// learn more: https://github.com/i18next/i18next-browser-languageDetector

	// pass the i18n instance to react-i18next.
	.use(initReactI18next)
	.use(LanguageDetector)
	// init i18next
	// for all options read: https://www.i18next.com/overview/configuration-options
	.init({
		fallbackLng: "vi",
		debug: false,
		lng: i18n.defaultLocale,
		returnNull: false,
		interpolation: {
			escapeValue: false,
		},
		detection: {
			// Cấu hình phát hiện ngôn ngữ
			order: ["cookie", "localStorage", "navigator", "htmlTag"], // Thứ tự ưu tiên của các phương thức phát hiện
			caches: ["cookie", "localStorage"], // Các nơi lưu trữ dữ liệu phát hiện
		},
		resources: i18nextResource,
	});

export default i18next;
