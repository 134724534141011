const Local = {
	storage: {
		wishlist: "CLIENT:wishlist",
		popup_event: "CLIENT:popup-event",
		wishlist_browser_id: "CLIENT:wishlist-browser-id",
		cart_local: "MAIN:cart",
		cart_local_id: "MAIN:cart-id",
		cart_last_time: "MAIN:cart-last-time",
		user: "MAIN:user",
		token: "MAIN:token",
		orders: "MAIN:orders",
		default_address: "CLIENT:default-address",
		browser_id: "MAIN:browser-id",
		productCompare:"CLIENT:product-compare",
		address_shipping:"ADDRESS_SHIPPING",
	},
	cookies: {
		language: "n-data-lng",
	},
};

export default Local;
