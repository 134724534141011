import BaseApi from "@/common/api/BaseApi";
import SettingApi from "@/common/constants/setting";
import { Option, callApi, callApiV2 } from "@/lib/config/Api";
import { getDataError } from "@/lib/config/apiHandler";
import {
	SettingJson,
	SttingCollectionJson,
	TimeServerJson,
} from "@/types/Setting.type";
import Helper from "@/utils/helper";

const DOMAIN = process.env.REACT_APP_BASE_DOMAIN;

export async function getSettingV2(
	key: string,
	params?: any,
	options?: Option
) {
	const res = await callApiV2<SettingJson>(`/settings/public/${key}`, "get", {
		params: params,
		revalidate: 30,
		...options,
	});
	return res;
}

export async function getTimeServer(key: string, params?: any) {
	const res = await callApiV2<TimeServerJson>(
		`/settings/public/${key}`,
		"get",
		{
			params: params ? { ...params } : { domain: DOMAIN },
		}
	);

	return res;
}

export async function getMultiSetting(
	key: string,
	param?: any,
	option?: Option
) {
	const params = Helper.convertFilterToParams(
		param
			? { keys: key, ...param, domain: DOMAIN }
			: { keys: key, domain: DOMAIN }
	);
	const res = await callApiV2<SttingCollectionJson>(
		`/settings/public${params}`,
		"get",
		{
			revalidate: 30,
			...option,
		}
	);

	return res;
}


export async function getStoreId() {

	  const res = await getSettingV2(SettingApi.KEY.k_setting_id_store_web_shop );
	  return Number(res.data.value) as number;

  }