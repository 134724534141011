import { ProductSortBy, ProductSortType } from "@/types/Product.type";

const ProductConstants = {
	PRODUCT_RECENT_VIEW: {
		KEY: "PRODUCTS_RENCENT_VIEW",
		LIMIT_NUMBER_SAVE: 20,
	},
	RESIZE_IMAGE_PRODUCT: 600,
	RESIZE_CARD_IMAGE_PRODUCT_MOBILE: 183,
	RESIZE_CARD_IMAGE_PRODUCT_DESKTOP: 170,

	RESIZE_IMAGE_PRODUCT_DETAIL: 390,
	RESIZE_IMAGE_PRODUCT_DETAIL_MINI: 45,
	RESIZE_IMAGE_PRODUCT_DETAIL_LARGE: 500,
};

export const CONFIG_TAGS_COLOR: { [any: string]: string } = {
	"limited-edittion": "bg-gray-500",
	new: "bg-red-500",
	sale: "bg-red-500",
};

export const CONFIG_TAGS_PRIORITY: { [any: string]: number } = {
	"limited-edittion": 1,
	new: 2,
	sale: 3,
};
export const CONFIG_TAGS_GENDER_COLOR: { [any: string]: string } = {
	nu: "bg-pink-500",
	nam: "bg-blue-400",
	unisex: "bg-yellow-500",
};
export interface SortByData {
	name?: string;
	isDefault?: boolean;
	sort_by: ProductSortBy | "";
	sort_type: ProductSortType | "";
}

export const sortByDataProduct: SortByData[] = [
	{
		name: "Mới nhất",
		sort_by: "date_created",
		sort_type: "DESC",
	},
	{
		name: "Cũ nhất",
		sort_by: "date_created",
		sort_type: "ASC",
	},
	{
		name: "Sản phẩm nổi bật",
		sort_by: "featured_products",
		sort_type: "DESC",
	},
	{
		name: "Bán chạy nhất",
		sort_by: "best_seller",
		sort_type: "DESC",
		isDefault: true,
	},

	{
		name: "Giá: Tăng dần",
		sort_by: "price",
		sort_type: "ASC",
	},
	{
		name: "Giá: Giảm dần",
		sort_by: "price",
		sort_type: "DESC",
	},

	{
		name: "Tên: A-Z",
		sort_by: "name",
		sort_type: "ASC",
	},
	{
		name: "Tên: Z-A",
		sort_by: "name",
		sort_type: "DESC",
	},
];

export default ProductConstants;
