import {
	Images,
	ProductActualQuantity,
	ProductAllowSale,
	ProductBrandJson,
	ProductImage,
	ProductImage2,
	ProductJson,
	ProductOptionJson,
	ProductRating,
	ProductTagJson,
} from "@/types/Product.type";
import BaseModel from "./BaseModel";
import { BaseModelJson } from "../types/BaseModelJson.type";
import { PromotionJson } from "@/types/Promotion.type";
import { PricePromotionJson } from "@/types/Cart.type";
import { Resize } from "~components/card";
import { DataLanguageCacheJson } from "../types/SiteLanguage.type";

const defaultDataProduct: ProductJson = {
	actuals: null,
	sku: "",
	status: 1,
	id: 0,
	name: "",
	handle: "",
	quantity: 0,
	brand: {
		handle: "",
		id: 0,
		title: "",
	},
	price_min: 0,
	price_max: 0,
	compare_at_price_min: 0,
	compare_at_price_max: 0,
	type_id: 0,
	images: [],
	tags: [],
	summary: "",
	description: "",
	created_at: 0,
	updated_at: 0,
	options: [],
	full_name: "",
	count_childs: 0,
	parent_id: -1,
	barcode: "",
	kiotviet_image: "",
	price: 0,
	compare_at_price: 0,
	detail: "",
	meta_description: "",
	meta_title: "",
	kiotviet_category: 0,
	kiotviet_id: 0,
	meta_keyword: "",
	allow_sale: ProductAllowSale.ALLOW_SALE,
	origin: "",
	production_year: 0,
	set_promotionids_from_active: false,

	//
	promotions: [],
	pricePreview: {
		discount: 0,
		item_quantity: 1,
		price_final: 0,
		price_Unit: 0,
		product_id: 0,
	},

	list_childs: [],
	rate: {
		rate: 0,
		count_rate: 0,
		count_rate_1: 0,
		count_rate_2: 0,
		count_rate_3: 0,
		count_rate_4: 0,
		count_rate_5: 0,
	},
	compare_discount: 0,
	compare_discount_pesent: 0,

	limit_sale: 10,
	display_from: 0,
	display_to: 0,
	option_name: "",
	collections: [],
	locale_description: {},
	locale_meta_description: {},
	locale_meta_keyword: {},
	locale_meta_title: {},
	related : []
};

class ProductModel extends BaseModel implements BaseModelJson<ProductJson> {
	status: number = defaultDataProduct.status;
	id: number = defaultDataProduct.id;
	name: string = defaultDataProduct.name;
	handle: string = defaultDataProduct.handle;
	quantity: number = defaultDataProduct.quantity;
	brand: ProductBrandJson | null = defaultDataProduct.brand;
	price_min: number = defaultDataProduct.price_min;
	price_max: number = defaultDataProduct.price_max;
	compare_at_price_min: number = defaultDataProduct.compare_at_price_min;
	compare_at_price_max: number = defaultDataProduct.compare_at_price_max;
	type_id: number = defaultDataProduct.type_id;
	images: Images[] = defaultDataProduct.images;
	tags: ProductTagJson[] = defaultDataProduct.tags;
	summary: string = defaultDataProduct.summary;
	description: string = defaultDataProduct.description;
	created_at: number = defaultDataProduct.created_at;
	updated_at: number = defaultDataProduct.updated_at;
	options: ProductOptionJson[] = defaultDataProduct.options;
	full_name: string = defaultDataProduct.full_name;
	count_childs: number = defaultDataProduct.count_childs;
	parent_id: number = defaultDataProduct.parent_id;
	barcode: string = defaultDataProduct.barcode;
	kiotviet_image: string = defaultDataProduct.kiotviet_image;
	price: number = defaultDataProduct.price;
	compare_at_price: number = defaultDataProduct.compare_at_price;
	sku: string = defaultDataProduct.sku;
	detail: string = defaultDataProduct.detail;
	meta_title: string = defaultDataProduct.meta_title;
	meta_description: string = defaultDataProduct.meta_description;
	kiotviet_id: number = defaultDataProduct.kiotviet_id;
	kiotviet_category: number = defaultDataProduct.kiotviet_category;
	meta_keyword: string = defaultDataProduct.meta_keyword;
	allow_sale: ProductAllowSale = defaultDataProduct.allow_sale;
	origin: string = defaultDataProduct.origin;
	production_year: number = defaultDataProduct.production_year;
	rate: ProductRating = defaultDataProduct.rate;
	//
	promotions?: PromotionJson[] = defaultDataProduct.promotions;
	pricePreview?: PricePromotionJson|null = defaultDataProduct.pricePreview;

	list_childs: number[] = defaultDataProduct.list_childs;

	compare_discount: number = defaultDataProduct.compare_discount;
	compare_discount_pesent: number = defaultDataProduct.compare_discount_pesent;

	limit_sale: number = defaultDataProduct.limit_sale;
	display_from: number = defaultDataProduct.display_from;
	display_to: number = defaultDataProduct.display_to;
	option_name = defaultDataProduct.option_name;
	collections = defaultDataProduct.collections;
	locale_description: DataLanguageCacheJson =
		defaultDataProduct.locale_description;
	locale_meta_description: DataLanguageCacheJson =
		defaultDataProduct.locale_meta_description;
	locale_meta_keyword: DataLanguageCacheJson =
		defaultDataProduct.locale_meta_keyword;
	locale_meta_title: DataLanguageCacheJson =
		defaultDataProduct.locale_meta_title;
	actuals: ProductActualQuantity|null = defaultDataProduct.actuals;
	
	constructor(json: ProductJson) {
		super();
		Object.assign(this, { ...defaultDataProduct, ...json });
	}
	static getDefaultData(): ProductJson {
		return { ...defaultDataProduct };
	}
	toJson(): ProductJson {
		return JSON.parse(JSON.stringify({ ...this }));
	}

	static generateUrl = (
		src: Images[],
		resize?: Resize | number,
		action?: "resize" | "crop"
	): string => {
		const photoAction = action || "resize";
		let url: string = "";
		// let width: number | undefined = undefined;
		// let height: number | undefined = undefined;
		// if (typeof resize === "number") {
		// 	width = resize;
		// 	height = resize;
		// } else {
		// 	(width = resize?.width), (height = resize?.height);
		// }
		if (src.length > 0) {
			url = src[0].url;
		}
		return url;
	};
}

export default ProductModel;
