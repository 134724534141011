import i18n from "@/lib/config/i18n-config";
import {
	DataLanguageCache,
	LanguageSettingJson,
	SiteLanguageItem,
} from "../types/SiteLanguage.type";

export const SiteLanguage: SiteLanguageItem[] = [
	{
		locale: "vi",
		locale_short: "vi",
		title: i18n.t("shop:language.vi"),
		name: "VN",
	},
	{
		locale: "en",
		locale_short: "en",
		title: i18n.t("shop:language.en"),
		name: "EN",
	},
];

export const LanguageSetting: LanguageSettingJson[] = [
	{
		key: "",
		label: "Tiếng Việt",
		url: "https://" + process.env.REACT_APP_BASE_DOMAIN,
		lang: "vi",
		version: "1.0.1",
	},
	// {
	// 	key: "",
	// 	label: "Tiếng Anh",
	// 	url: "https://" + process.env.REACT_APP_BASE_DOMAIN,
	// 	lang: "en",
	// 	version: "1.0.1",
	// },
];

export const DataLanguage = {
	vi: {
		cancle: "Hủy",
		delete: "Xóa",
		accept: "Đồng ý",
		remove: "Gỡ",
		home_header_top_left_1: "Freeship cho mọi đơn hàng",
		home_header_top_left_2: "Thương hiệu nước hoa uy tín từ 2013",
		home_header_order_tracking: "Theo dõi đơn hàng",
		home_recent_announcements: "Thông Báo gần đây",
		home_new_notification: "Thông báo mới",
		home_order_tracking: "Theo dõi đơn hàng",
		home_announcement_bar:
			"Thương hiệu nước hoa được feedback nhiều nhất Việt Nam.",
		home_announcement_detail: "Xem chi tiết",
	},
	en: {
		cancle: "Cancle",
		delete: "Delete",
		accept: "Accept",
		remove: "Remove",
		home_header_top_left_1: "Free shipping for all orders",
		home_header_top_left_2: "Prestigious perfume brand since 2013",
		home_header_order_tracking: "Order tracking",
		home_recent_announcements: "Recent announcements",
		home_new_notification: "New Notification",
		home_order_tracking: "Order Tracking",
		home_announcement_bar: "Perfume brand with the most feedback in Vietnam.",
		home_announcement_detail: "See details",
	},
};

export const defaultLocale = LanguageSetting[0];

export const DatalanguageCache = {
	space: ["home", "header"],
	data: DataLanguage.vi,
};
