import { BaseCollectionJson } from "@/common/types/BaseCollection.type";
import { PromotionJson } from "./Promotion.type";
import { PricePromotionJson } from "./Cart.type";
import { FileJson } from "@/common/types/File.type";
import { DataLanguageCacheJson } from "@/common/types/SiteLanguage.type";

export type ProductTagTypeJson =
	| "PRODUCT STRUCT"
	| "PRODUCT DESCRIPTION"
	| "PRODUCT DETAIL"
	| "PRODUCT CARD"
	| "PRODUCT GENDER"
	| "USE_AND_STORAGE"
	| "SHIPPING_AND_RETURNS"
	| "PRODUCT TESTER"
	| "PRODUCT STATUS"
	| "PRODUCT STYLE"
	| "PRODUCT PERFAMER"
	| "PRODUCT SIZE";

export enum ProductTesterValue {
	full = "Full",
	unbox = "Unbox",
	tester = "Tester",
}
export type ProductTagJson = {
	name: string;
	type: ProductTagTypeJson;
	code: string;
	description: string;
	value: string;
	id: number;

	style?: {
		color: string;
		bg_color: string;
	};
};

export enum ProductAllowSale {
	NOT_ALLOW_SALE,
	ALLOW_SALE,
}
export enum ProductShowWeb {
	NOT_ALLOW_SALE,
	ALLOW_SALE,
}
export type Images = {
	url: string;
};

export type ProductJson = {
	allow_sale: ProductAllowSale;
	status: number;
	id: number;
	name: string;
	full_name: string;
	sku: string;
	barcode: string;
	handle: string;
	quantity: number;
	brand: ProductBrandJson | null;
	price: number;
	compare_at_price: number;
	price_min: number;
	price_max: number;
	compare_at_price_min: number;
	compare_at_price_max: number;
	type_id: number;
	images: Images[];
	tags: ProductTagJson[];
	created_at: number;
	updated_at: number;
	parent_id: number;
	options: ProductOptionJson[];
	count_childs: number;
	summary: string;
	description: string;
	detail: string;

	meta_keyword: string;
	meta_title: string;
	meta_description: string;

	locale_description: DataLanguageCacheJson;
	locale_meta_keyword: DataLanguageCacheJson;
	locale_meta_title: DataLanguageCacheJson;
	locale_meta_description: DataLanguageCacheJson;
	//key client custom for productJson to process promotion
	promotions: PromotionJson[];
	set_promotionids_from_active?: boolean;
	pricePreview: PricePromotionJson | null;
	option_name: string;

	kiotviet_image: string;
	kiotviet_id: number;
	kiotviet_category: number;
	production_year: number;
	origin: string;

	list_childs: number[];
	rate: ProductRating;

	//price discount / price compare
	compare_discount: number;
	compare_discount_pesent: number;

	limit_sale: number;
	display_from: number;
	display_to: number;
	collections: ProductColletionJson[];

	actuals: ProductActualQuantity | null;
	related: ProductRelated[];
};

export interface ProductRelated {
	type: ProductTagTypeJson;
	name: string;
	code: string;
	items: number[];
}

export type ProductColletionJson = {
	id: number;
	title: string;
	handle: string;
};
export type ProductBrandJson = {
	id: number;
	title: string;
	handle: string;
};
export type ProductCollectionJson = BaseCollectionJson<ProductJson>;
export type ProductTagsCollectionJson = BaseCollectionJson<ProductTagJson>;
export type ProductImage = {
	file_path: string;
	width: number;
	height: number;
	title: string;
	description: string;
	url: string;
};

export type ProductImage2 = {
	url: string;
	file_path: string;
};
export type ProductOptionJson = {
	product_id: number;
	option_id: number;
	name: string;
	value: string;
};

export type ProductActualQuantity = {
	product_id: number; // id product
	quantity: number; // tổng số tồn kho
	actual_quantity: number; // tổng tồn kho thật sự
};

export type ProductSortBy =
	| "product_id"
	| "name"
	| "sku"
	| "date_created"
	| "date_modified"
	| "price"
	| "best_seller"
	| "featured_products";
export type ProductSortType = "DESC" | "ASC";

export type ProductShow = "web" | "pos";

export type FilterProduct = {
	page: number;
	parent_id?: number;
	store_id?: number | string;
	limit?: number;
	sort_by?: ProductSortBy;
	keyword?: string;
	price_min?: number;
	price_max?: number;
	brand?: string;
	brand_id?: number | string;
	collection_id?: number | string;
	variant_id?: number;
	list_product_id?: string;
	tags?: string;
	sort_type?: ProductSortType;
	rating?: number;
	id?: number;
	// show_web?: boolean;
	is_not_parent_id?: boolean;
	show_web?: ProductShowWeb;
	show?: ProductShow;
	show_promotion_price?: 1 | 0;
};

export interface ProductRating {
	rate: number;
	count_rate: number;
	count_rate_1: number;
	count_rate_2: number;
	count_rate_3: number;
	count_rate_4: number;
	count_rate_5: number;
}

export type ProductSettingTagJson = {
	name: string;
	code: string;
	id: number;
};

export type ProductDescriptionSetting = {
	type: string;
	title: string;
	content: string;
};

export interface ProductRangePrice {
	price_min: number;
	price_max: number;
}
export interface RangeSliderState {
	priceMin: number;
	priceMax: number;
}

export interface ProductSample {
	[key: string]: boolean;
}
