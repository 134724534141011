import coupon from "./data/coupon.json";
import cart from "./data/cart.json";
import shop from "./data/shop.json";
import payment from "./data/payment.json";
import customer from "./data/customer.json";
import address from "./data/address.json";

const resource = {
  coupon,
  cart,
  shop,
  payment,
  customer,
  address,
};

export default resource;
